<template>
  <div>
    <div class="filter-panel">
      <CSSelect style="width: 185px; margin-right: 0">
        <el-date-picker
          v-model="queryParams.startDate"
          type="date"
          style="width: 157px"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          placeholder="请选择日期"
          prefix-icon="el-icon-time"
          :editable="false"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </CSSelect>
      <span class="inline-gray"></span>
      <CSSelect style="width: 185px">
        <el-date-picker
          v-model="queryParams.endDate"
          type="date"
          style="width: 157px"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          placeholder="请选择日期"
          prefix-icon="el-icon-time"
          :editable="false"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </CSSelect>
      <div
        :style="{ position: 'relative', display: 'inline-block' }"
        @click.stop="staffTreeVisible = !staffTreeVisible"
        v-show="
          [
            DEPARTMENT_TYPES.DIRECTOR,
            DEPARTMENT_TYPES.MANAGER,
            DEPARTMENT_TYPES.PERSONNEL,
            DEPARTMENT_TYPES.PRESIDENT_OFFICE,
          ].includes(+dutyType)
        "
      >
        <CSSelect>
          <div :style="{ width: '215px', padding: '0 6px' }">
            <template v-if="Object.keys(checkedDepartment).length > 0">
              {{
                checkedDepartment.length == department_check.length
                  ? "全部部门"
                  : `已选${Object.keys(checkedDepartment).length}个部门`
              }}
            </template>
            <template v-else>
              <option value="">请选择</option>
            </template>
          </div>
          <div>
            <TreePanel v-show="staffTreeVisible">
              <CSTree :tree="department_check" @change="changeStaff" />
            </TreePanel>
          </div>
        </CSSelect>
      </div>
      <button class="btn btn-primary" @click="getAttendanceSummary">
        查询
      </button>
    </div>

    <div class="result-panel">
        <CSTable>
            <template v-slot:header>
                <div class="table-header-panel">
                    <button
                        class="btn btn-primary pull-right sticky-right"
                        @click="downloadExcel"
                        v-show="[DEPARTMENT_TYPES.DIRECTOR,DEPARTMENT_TYPES.MANAGER,DEPARTMENT_TYPES.PERSONNEL,].includes(+dutyType)"
                    >
                        下载考勤汇总
                    </button>
                    <p>
                        全勤率：
                        <span
                            v-for="(item, index) in AttendanceList"
                            :key="`${index}Attendance`"
                        >
                            {{ DEPARTMENT_TYPE_STR[Object.keys(item)[0]] }}
                            {{Object.values(item)[0] == "null%" ? "0.00%、" : Object.values(item)[0] }}
                            {{index === AttendanceList.length-1 ? '' : '、'}}
          </span>
                    </p>
                </div>
            </template>
we
            <template v-slot:thead>
                <tr>
                    <th>部门</th>
                    <th>姓名</th>
                    <th>迟到次数</th>
                    <th>迟到总时长</th>
                    <th>早退次数</th>
                    <th>早退总时长</th>
                    <th>上班缺卡次数</th>
                    <th>下班缺卡次数</th>
                    <th>旷工天数</th>
                    <th>无排班打卡</th>
                    <th>考勤状况</th>
                </tr>
            </template>
            <template v-slot:tbody>
                <template v-if="dataListAttendanc&&dataListAttendanc.length > 0">
                    <template v-for="(item, index) in dataListAttendanc">
                        <tr :key="`${item.userId}${index}`">
                            <td>{{ DEPARTMENT_TYPE_STR[item.dutyType] }}</td>
                            <td>{{ item.staffName }}</td>
                            <td>
                                {{ item.numberOfLateness ? item.numberOfLateness : "-" }}
                            </td>
                            <td>{{ item.totalTimeLate }}</td>
                            <td>{{ item.leaveEarly ? item.leaveEarly : "-" }}</td>
                            <td>{{ item.totalTimeLeftEarly }}</td>
                            <td>
                                {{
                                    item.totalStartWorkNotOpen
                                        ? item.totalStartWorkNotOpen
                                        : "-"
                                }}
                            </td>
                            <td>
                                {{
                                    item.totalGetOffWorkNotOpen
                                        ? item.totalGetOffWorkNotOpen
                                        : "-"
                                }}
                            </td>
                            <td>{{ item.numberOfDaysAbsentFromWork }}</td>
                            <td>{{ item.totalNoArrangement }}</td>
                            <td>{{ item.attendanceDetail }}</td>
                        </tr>
                    </template>
                </template>
            </template>
        </CSTable>
    </div>
  </div>
</template>

<script>
import TreePanel from "@/components/common/TreePanel";
import CSTree from "@/components/common/CSTree";
import CSSelect from "@/components/common/CSSelect";
import dayjs from "dayjs";
import {
  getAttendanceSummaryUrl,
  downloadAttendanceCountUrl,
} from "@/requestUrl";
import { DEPARTMENT_TYPE_STR, DEPARTMENT_TYPES } from "@/constant";
import CSTable from "@/components/common/CSTable";

const lastMonth = dayjs().subtract(1, "month").format("YYYY-MM"),
  largestNumberDaysMonth = [
    31,
    dayjs().year() % 4 === 0 ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ];
export default {
  name: "AttendanceSummary",
  components: {
      CSTable,
    TreePanel,
    CSTree,
    CSSelect,
  },
  data() {
    return {
      //是否显示下拉框
      staffTreeVisible: false,
        filterHeight: 0,
      //访问部门
      department_check: [],
      DEPARTMENT_TYPE_STR,
      DEPARTMENT_TYPES,
      //请求参数
      queryParams: {
        regionCode: this.$vc.getCurrentRegion().code, //必填
        dutyType: [], //必填 数组
        startDate: lastMonth + "-01",
        endDate: `${lastMonth}-${
          largestNumberDaysMonth[dayjs(lastMonth).month()]
        }`,
      },
      //存储获取的表格数据
      dataListAttendanc: [],
      //存储全勤率数据
      AttendanceList: [],
      //存储选中的部门
      checkedDepartment: [],
      dutyType: this.$vc.getCurrentStaffInfo().dutyType,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() + 24 * 60 * 60 * 1000 > Date.now();
        },
      },
    };
  },
  created() {
    for (let id in DEPARTMENT_TYPE_STR) {
        if (![
                DEPARTMENT_TYPES.DIRECTOR,
                DEPARTMENT_TYPES.MANAGER,
                DEPARTMENT_TYPES.PRESIDENT_OFFICE
            ].includes(+id)
        ) {
            this.department_check.push({
                name: DEPARTMENT_TYPE_STR[id],
                id,
                checked: true,
            });
        }
    }
    window.addEventListener("keydown", this.queryDown);

    document.body.addEventListener("click", this.clickBody);
    this.checkedDepartment = this.department_check.filter((item) => {
      return item.checked;
    });
    this.getAttendanceSummary();
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.clickBody);
    window.removeEventListener("keydown", this.queryDown);
  },
  deactivated(){
     window.removeEventListener("keydown", this.queryDown);
  },
  mounted() {
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
      window.addEventListener('resize', () => {
          this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
      })
  },
  computed: {
    getDutyTypeArr() {
      return this.checkedDepartment.map((item) => {
        return item.id;
      });
    },
  },
  methods: {
    //获取全勤率
    fullAttendanceRate(idCode) {},
    //点击body
    clickBody() {
      this.staffTreeVisible = false;
    },
    //下拉框改变事件处理函数
    changeStaff(obj) {
      const item = obj.source[obj.key];
      //const checked = item.checked;
      item.checked = !item.checked;

      this.checkedDepartment = this.department_check.filter((item) => {
        return item.checked;
      });
    },
    //发送get请求 获取渲染数据
    getAttendanceSummary() {
      this.queryParams.dutyType = this.getDutyTypeArr;
      this.$fly.post(getAttendanceSummaryUrl, this.queryParams).then((res) => {
        if (res.code !== 0) {
          return;
        }
        this.dataListAttendanc = res.data.attendanceDataList;
        this.AttendanceList = res.data.attendanceList.filter((item) => {
          if (
            [
              DEPARTMENT_TYPES.DIRECTOR,
              DEPARTMENT_TYPES.MANAGER,
              DEPARTMENT_TYPES.PERSONNEL,
            ].includes(+this.dutyType)
          ) {
            return true;
          }
          return item.hasOwnProperty(this.dutyType);
        });
      });
    },
    //回车
    queryDown(e) {
      if (e.keyCode !== 13) {
        return;
      }
      this.getAttendanceSummary();
    },
    //下载excel AttendanceSummary.vue
    downloadExcel() {
      this.$fly
        .post(downloadAttendanceCountUrl, this.queryParams, {
          responseType: "arraybuffer",
          headers: {
            notParse: true,
          },
        })
        .then((res) => {
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const fileName = `考勤汇总.xlsx`;
          if ("download" in document.createElement("a")) {
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          } else {
            navigator.msSaveBlob(blob, fileName);
          }
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
.result-panel-header:after {
  content: '';
  display: block;
  clear: both;
}

.result-panel-header {
  padding-bottom: 10px;
  padding-top: 10px;
  height: auto;
}

.result-panel-header p {
  line-height: 35px;

  margin-bottom: 0;
}
</style>
